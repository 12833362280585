<template>
  <div class="studentAppForm">
    <h2 class="page-title">
      <div class="fill-status-tips">
        <p class="status" v-for="item in stateArr" :key="item">
          <span class="dot"></span>
          <span class="text">{{item}}</span>
        </p>
      </div>
    </h2>
    <div class="formInfo">
      <student-all-form-info
        ref="allFormComp"
        lang="zh"
        :student-id="getStudentId"
        :agent-id="scholarshipApplyData.agentId"
        v-if="scholarshipApplyData.token"
        :at="scholarshipApplyData.token"
        :public-files-url="scholarshipApplyData.publicFilesUrl"
        :private-files-url="scholarshipApplyData.privateFilesUrl"
        fk-platform-type="get_issue"
        :company-id="2"
        :had-course-id="hadCourseId"
        :platform-limit-list="['get_scholarship']"
        @submit-form-success="handleSubmitFormSuccess"
        @updateStudentId="updateStudentId"
        />
    </div>
  </div>
</template>
<script>
import StudentAllFormInfo from '/packages/student-all-form-info/src/main'
import wx from 'weixin-js-sdk'
export default {
  name: 'scholarshipToForm',
  components: {StudentAllFormInfo},
  data() {
    return {
      stateArr: ['已完成', '未完成', '当前', '未启动' ],
      scholarshipApplyData: {
        studentId: null, // 学生id
        agentId: '', // 代理id
        privateFilesUrl: '',
        publicFilesUrl: '',
        token: '',
        countryId: '', // 国家id
        schoolId: '', // 学校id
        majorLevelId: '', // 课程等级id
        courseId: '', // 课程id
      },
      hadCourseId: false, // 是否有课程id
    }
  },
  computed:{
    getStudentId(){
      return this.scholarshipApplyData.studentId
    }
  },
  mounted() {
    if(this.$route.query && this.$route.query.scholarshipApplyData) {
      const data = this.$route.query.scholarshipApplyData;
      sessionStorage.setItem('scholarshipApplyData', data)
      this.scholarshipApplyData = JSON.parse(data)
      wx.miniProgram.postMessage({ data: 'back'})
    }else {
      // this.scholarshipApplyData.token = ""
      // this.scholarshipApplyData.agentId = 1;
      // this.scholarshipApplyData.studentId = 3188
    }
    this.hadCourseId = Boolean(this.scholarshipApplyData.courseId);
    document.title = '学生申请表单';
  },
  methods: {
    handleSubmitFormSuccess() {
      wx.miniProgram.navigateBack({delta: 1})
    },
    updateStudentId(id){
      this.scholarshipApplyData.studentId=id;
    }
  }
}
</script>
<style lang='scss' scoped>
.studentAppForm {
  padding: 18px;
  .page-title {
    display: flex;
    justify-content: flex-start;
    .title {
      font-weight: bold;
    }
    .fill-status-tips {
      display: flex;
      font-size: 14px;
      .status {
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          margin: 6px;
        }
        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
        &:nth-child(1) {
          .dot {
            background: #00C6A3;
          }
        }
        &:nth-child(2) {
          .dot {
            background: #E74C3C;
          }
        }
        &:nth-child(3) {
          .dot {
            background: #1E88F0;
          }
        }
        &:nth-child(4) {
          .dot {
            background: #D4D4D4;
          }
        }
      }
      .status + .status {
        margin-left: 20px;
      }
    }
  }
  .formInfo {
    background: white;
  }
}
@media screen and(max-width: 768px) {
  .studentAppForm {
    padding: 8px;
    .nav-comp-ul {
      padding-left: 0 !important;
    }
  }
}
</style>
